<template lang="pug">
  .load-more
    app-button(
      v-if="pagination.next"
      :loading="loading"
      color="#f4f7f9"
      @click.native="$emit('loadMore')"
      hide-shadow
      block
    )
      span.load-more__text.unselectable Load More
</template>

<script>
export default {
  name: 'LoadMoreButton',

  props: {
    loading: {
      type: Boolean,
      required: true
    },
    pagination: {
      type: [Array, Object],
      required: true
    }
  },

  components: {
    AppButton: () => import('@/components/global/actions/BaseButton.vue')
  }
}
</script>

<style lang="scss" scoped>
.load-more {
  margin-bottom: 15px;

  &__text {
    text-transform: uppercase;
    color: rgb(0, 0, 0, 0.65);
  }
}
</style>
